import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Header from "../components/header"
import SEO from "../components/seo"
import PostList from "../components/postlist"

const IndexPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  return (
    <Layout>
      <SEO title={"Documentation"} />
      <Header /> 
      <h2>Documentation</h2>
      <p style={{ marginBottom: "40px" }}>Snag the <a href="https://github.com/binx/beluga/">beluga git repo</a> to get started 🎉</p>
      <PostList edges={edges.map(v => v.node.frontmatter)} />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql `
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date
            path
            header
            title
          }
        }
      }
    }
  }
`