import React from "react"
import { Link } from "gatsby"
import styled from "styled-components";

const Name = styled.span `
  color: #555;
  font-family: 'Fredoka One', Helvetica Neue, sans-serif;
  letter-spacing: -1px;

  @supports (-webkit-text-stroke: 1px black) {
    -webkit-text-stroke: 1px #6598A8;
    -webkit-text-fill-color: #F5FAFF;
  }
`;
const Logo = styled.div `
  font-size: ${props => props.index ? 5 : 2.5}rem;

  @media (max-width: 640px) {
    font-size: ${props => props.index ? 3.5 : 2.5}rem;
  }
  @media (min-width: 640px) {
    flex: ${props => props.index ? "inherit" : 1};
  }

  ${Name} {
    margin-left: ${props => props.index ? 1.5 : .75}rem;
  }
`;
const NavLink = styled.div `
  padding: .5rem 0;
  &:last-child a {
    border-right: none;
    padding-right: 0;
  }

  a {
    color: white;
    letter-spacing: .5px;
    padding: 0 1rem;
    font-weight: bold;
    border-right: 1px solid #fff;
  }
`;
const NavWrapper = styled.div `
  display: flex;
  justify-content: center;
`;
const HeaderDiv = styled.div `
  background: #A3D0FF;
  margin: -40px -40px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: ${props => props.index ? "column" : "inherit"};
  padding: ${props => props.index ? "0" : ".5rem 2rem" };

  ${NavWrapper} {
    background: ${props => props.index ? "rgba(102,143,210,.6)" : "inherit"};
    width: ${props => props.index ? "100%" : "auto"};
  }

  @media (max-width: 640px) {
    margin: -20px -20px 40px;
  }
`;

const Header = ({ index }) => (
  <HeaderDiv index={index}>
    <Logo index={index}>
      <Link to="/" style={{ textDecoration: `none`}}>
        <span role="img" aria-label="beluga">🐋</span>
        <Name>beluga</Name>
      </Link>
    </Logo>
    <NavWrapper>
      <NavLink>
        <Link to="/docs">Documentation</Link>
      </NavLink>
      <NavLink>
        <Link to="/gallery">Gallery</Link>
      </NavLink>
      <NavLink>
        <Link to="/about">About</Link>
      </NavLink>
    </NavWrapper>
  </HeaderDiv>
)

export default Header