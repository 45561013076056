import React from "react"
import { Link } from "gatsby"
import { nest } from "d3-collection";
import styled from "styled-components";

const Line = styled.li `
  padding-left: 10px;
  font-size: 14px;
  margin-bottom: 2px;
  line-height: 1.5em;

  a {
    color: black;
    text-decoration: none;
    display: block;
    padding: 2px 0;
  }
  @media (min-width: 640px) {
    font-size: 16px;
    :hover {
      background: rgba(102,143,210,.15);
    }
  }
`;
const Header = styled.h4 `
  border-bottom: 2px solid rgba(102,143,210,.5);
  background: rgba(102,143,210,.05);
  padding: 3px 5px 2px;
`;

const PostList = ({ edges }) => {
  const posts = nest().key(d => d.header)
    .entries(edges.sort((a, b) => new Date(a.date) - new Date(b.date)))

  return (
    <div>
      {posts.map((p, i) => (
        <div key={`type${i}`}>
          <Header>{p.key}</Header>
          <ol style={{ color: "#888" }}>
            {p.values.map((v,j) => (
              <Line key={`link${i}-${j}`}>
                <Link to={v.path}>
                  {v.title}
                </Link>
              </Line>
            ))}
          </ol>
        </div>
      ))}
    </div>
  )
}

export default PostList